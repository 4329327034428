import React, { useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { decode } from "html-entities";
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import { noop as _noop } from "lodash";
import { usePostHog } from "posthog-js/react";
import queryString from "query-string"
import "@reach/skip-nav/styles.css";

import { Helmet } from "react-helmet";

import Header from "./header/Header";
import Footer from "../components/footer/Footer";

import "../styles/layout.css";
import ProximaNovaUrl from "../fonts/proxima_ssv/ProximaNova-Regular.otf";

const Layout = React.memo((props) => {
  const posthog = usePostHog();
  const webview = props.children?.props.location.pathname.indexOf('/webview') > -1;
  const state = useSelector((state) => state);

  let pageContext = props.children?.props.pageContext;

  // this needs to not be here
  const containerStyle = { marginTop: "88px", minHeight: "704px" };

  const bannerData =
    state.hydrate.siteConfigData?.blocks &&
    state.hydrate.siteConfigData?.blocks["interstitial--news-banner"]
      ? state.hydrate.siteConfigData?.blocks["interstitial--news-banner"]
          .component_data
      : null;

  const parsed = props.children?.props.location ? queryString.parse(props.children.props.location.search) : null;

  // no site config here means we're in Gatsby dev mode error
  if (!pageContext || !pageContext.site_config) {
    return (
      <>
        <SkipNavLink />
        { !parsed.kiosk && <Header location={props.children?.props.location} bannerData={null} /> }

        <Helmet htmlAttributes={{ lang: "en" }} encodeSpecialCharacters={false}>
          <meta charSet="utf-8" />
          <meta name="MobileOptimized" content="width" />
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <link
            rel="preload"
            as="font"
            href={ProximaNovaUrl}
            type="font/otf"
            crossOrigin="anonymous"
          />
        </Helmet>

        <SkipNavContent />

        <div style={containerStyle}>{props.children}</div>

        { !parsed.kiosk && <Footer /> }
      </>
    );
  }

  const title =
    pageContext.content &&
    pageContext.content.page_meta &&
    pageContext.content.page_meta.meta_title &&
    pageContext.content.page_meta.meta_title.length > 0
      ? decode(pageContext.content.page_meta.meta_title)
      : decode(pageContext.title);

  const desc =
    pageContext.content &&
    pageContext.content.page_meta &&
    pageContext.content.page_meta.meta_description &&
    pageContext.content.page_meta.meta_description.length > 0
      ? decode(pageContext.content.page_meta.meta_description)
      : decode(pageContext.site_config.seo.meta_description);

  const metaTitle = pageContext.site_config
    ? title + " | " + decode(pageContext.site_config.site_title)
    : "";

  const metaDesc = pageContext.site_config ? desc : null;

  const metaRobots = pageContext.site_config
    ? decode(pageContext.site_config.seo.meta_robots)
    : null;

  const jsonLdLocalBusiness = pageContext.content
    ? getStructuredData("LocalBusiness", state, pageContext.content)
    : null;

  const layoutData = state.hydrate.layoutData;
  const layoutContext = layoutData?.context;

  const image =
    "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/bltb5633940064f8b56/6509cb622a4e0f328400e4a6/cannabist-company.png";

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en" }}
        encodeSpecialCharacters={false}
        title={`${layoutContext ? layoutData.title : metaTitle}`}
      >
        {jsonLdLocalBusiness && (
          <script type={jsonLdLocalBusiness.type}>
            {jsonLdLocalBusiness.innerHTML}
          </script>
        )}
        <meta charSet="utf-8" />
        <meta name="MobileOptimized" content="width" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="description" content={`${metaDesc}`} />
        <meta name="robots" content={`${metaRobots}`} />

        <meta property="og:title" content={`${metaTitle}`} />
        <meta property="og:description" content={`${metaDesc}`} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={image} />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="418" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${metaTitle}`} />
        <meta name="twitter:site" content="@cann_company" />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:image:alt" content="The Cannabist Company" />
        <meta name="twitter:description" content={`${metaDesc}`} />

        <link
          rel="preload"
          as="font"
          href={ProximaNovaUrl}
          type="font/otf"
          crossOrigin="anonymous"
        />
      </Helmet>

      { !webview && <Header
        location={props.children?.props.location}
        bannerData={bannerData}
        posthog={posthog}
      /> }

      {/* {
        (
          () => {
            return (
              <span ref={ contentsquareRef }>
                <noscript>
                  <iframe 
                    src={ `https://www.googletagmanager.com/ns.html?id=${GTM_ID}` }
                    height="0" 
                    width="0" 
                    style={ 
                      {
                        display: 'none',
                        visibility: 'hidden' 
                      }
                    }
                  />
                </noscript>
              </span>
            );
          }
        )()
      } */}

      <div style={containerStyle}>
        <SkipNavContent />
        {React.Children.map(props.children, (child) =>
          React.cloneElement(child, { posthog })
        )}
      </div>
      { !webview && <Footer posthog={posthog} /> }
    </>
  );
});

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.displayName = "Layout";

export default Layout;

// @todo: move to common lib
const getStructuredData = (type, state, content) => {
  const location = state.hydrate.locationData?.location;
  if (!location) {
    return null;
  }

  const seo = content.page_meta ? content.page_meta : content.seo;

  switch (type) {
    case "LocalBusiness":
      const latitude = location.address?.latitude;
      const longitude = location.address?.longitude;

      // @todo: do we want to put hours? seems redundant
      return {
        type: "application/ld+json",
        innerHTML: `{
            "@context": "http://schema.org",
            "@type": "LocalBusiness",
            "url": "https://cannabistcompany.com/locations/${
              location.location_id
            }",
            "address": {
                "@type": "PostalAddress",
                "addressLocality": "${location.address.city}",
                "addressRegion": "${location.address.state}",
                "streetAddress": "${location.address.street_address_1} ${
          location.address.street_address_2
        }",
                "postalCode": "${location.address.zip_code}",
                "addressCountry": "US"
            },
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "${parseFloat(latitude)}",
                "longitude": "${parseFloat(longitude)}"
            },
            "additionalType": ${JSON.stringify(seo.meta_additionaltype)},
            "sameAs": ${seo ? JSON.stringify(seo.meta_sameas) : ""},
            "image": [ "${""}" ],
            "name": "${"The Cannabist Company"}",
            "telephone": "${location.store_information.telephone}"
          }`,
      };
    default:
      return null;
  }
};
