// import { abortRequests } from '@redux-requests/core';

import { FETCH_PAGE, ON_PAGE_FETCHED } from "./constants";
import { stripTrailingSlash } from "../../../../helpers";

import { 
  transformLayoutData,
  transformLocationData,
  transformPostData
} from "../../../contentstack/modular-blocks";

import { lookupContentType } from "..";
import { 
  doSetLayoutData,
  doSetLocationData,
  doSetPostData
} from "../../../../redux/hydrate/actions";

// &include[]=modular_blocks.page_latest_news.news_items
export const fetchPage = (path, type) => {
  const contentType = type || lookupContentType(path);
  let thisPath = contentType === 'media_coverage' ? path + '/' : path
  thisPath = thisPath.split('/webview')[0]
  console.log(thisPath)
  let endpoint = `${ 'https://cdn.contentstack.io' }/v3/content_types/${ contentType }/entries?environment=${process.env.GHUB_CONTENTSTACK_ENVIRONMENT}&query={"url": "${ thisPath === '/' ? '/' : stripTrailingSlash(thisPath) }"}`;
  // @todo: critical! gotta figure out way to abstract this b/c messy
  // @update: not used at the moment, however
  // if (path.indexOf('/news') === 0) {
  //   endpoint += `&include[]=modular_blocks.page_latest_news.latest_news_items`;
  // }
  return {
    type: FETCH_PAGE,
    request: {
      url: endpoint,
      headers: {
        access_token: process.env.GHUB_CONTENTSTACK_DELIVERY_TOKEN,
        api_key: process.env.GHUB_CONTENTSTACK_STACK_KEY
      },
    },
    meta: {
      onRequest: (request, requestAction, store) => {
        // if (process.env.NODE_ENV == 'development') {
        //   store.dispatch(abortRequests([FETCH_PAGE]));
        // }
        return request;
      },
      onSuccess: (response, requestAction, store) => {
        // console.log('before:', response.data)
        if (!response.data.entries[0]) {
          console.warn('No page content was found for this path', path)
          // @todo: standardize empty responses across fetching methods
        }
       
        let resData 
        if (process.env.GHUB_DATADOG_SERVICE.indexOf('-oh-comms') > -1) {
          response.data.entries.find(({ sub_site_variant }, index) => {
            if (sub_site_variant?.site && sub_site_variant.site[0]) {
              resData = response.data.entries[index]
              return true
            }
            else {
              resData = response.data.entries[0]
            }
          })
        }
        else {
          response.data.entries.find(({ sub_site_variant }, index) => {
            if (sub_site_variant?.site && !sub_site_variant.site[0]) {
              resData = response.data.entries[index]
              return true
            }
            else {
              resData = response.data.entries[0]
            }
          })
        }
        const page = resData;
        const responseData = {
          layoutData: transformLayoutData(page),
        };

        store.dispatch(doSetLayoutData(responseData.layoutData))
        if (contentType === 'Location') {
          responseData.locationData = transformLocationData(page);
          store.dispatch(doSetLocationData(responseData.locationData))
        }
        if (contentType === 'media_coverage') {
          responseData.postData = transformPostData(page);
          store.dispatch(doSetPostData(responseData.postData))
        }
        // console.log('after:', responseData)
        response.data = responseData;
        return response;
      },
      onError: (error, requestAction, store) => {
        throw error;
      },
      onAbort: (requestAction, store) => {
        console.log("FETCH_PAGE aborted");
      },
    },
  };
};

export const onPageFetched = () => ({
  type: ON_PAGE_FETCHED,
});
