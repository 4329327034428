import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import ConsumerQueue from "consumer-queue";
import queryString from "query-string";
import { useQuery, useSubscription } from "@redux-requests/react";
// import useFontFaceObserver from "use-font-face-observer";

import {
  fetchPage,
  onPageFetched,
} from "../../../../common/providers/contentstack/content-types/page/actions";
import {
  FETCH_PAGE,
  ON_PAGE_FETCHED,
} from "../../../../common/providers/contentstack/content-types/page/constants";

import { lookupContentType } from "../../../../common/providers/contentstack/content-types";

import { useDataDogRum } from "../effects/useDataDogRum";

import { getReduxStore } from "../state/configureStore";
import { getBoundActions } from "../state/actions";
import {
  transformLayoutData,
  transformLocationData,
  transformPostData,
  transformSiteConfigData,
  transformBuildInfoData,
  transformDatabagData,
} from "../../../../common/providers/contentstack/modular-blocks";

import { useCookieWatcher } from "../effects/useCookie";

// import { checkUserTrackingConsent } from "../../../../common/helpers";

import Layout from "./Layout";
import Modal from "./modal/Modal";
import AppScripts from "./AppScripts";

const App = (props) => {
  const store = getReduxStore("init", props.location.pathname).store;
  const actions = getBoundActions(store.dispatch);
  const state = store.getState();

  // we don't actually use this value but watch for the change in value
  const iubCookyExists = useCookieWatcher("_iub_cs", 100, "loose");

  const [isAppMounted, setIsAppMounted] = useState(false);
  const [isHydrateMounted, setIsHydrateMounted] = useState(false);
  const [hydrateData, setHydrateData] = useState(null);
  const [currentPath, setCurrentPath] = useState(null);
  const [modalQueue, setModalQueue] = useState(new ConsumerQueue());
  const [activeModal, setActiveModal] = useState(null);
  const [hideActiveModal, setHideActiveModal] = useState(false);
  const [userConsentsToTracking, setUserConsentsToTracking] = useState(false);
  const [userShownAgeGate, setUserShownAgeGate] = useState(false);

  const [disableClientFetch, setDisableClientFetch] = useState(false);

  const cookies =
    typeof document === "object" ? new Cookies(document.cookie) : {};

  // we have to keep track of embedded scripts
  const [embedScripts, setEmbedScripts] = useState({
    "iubenda-stub": null,
    "iubenda-cs": null,
    "block-cookies-test": null,
    "contentsquare-embed": null,
    "twitter-embed": null,
  });

  useQuery({
    type: FETCH_PAGE,
    action: fetchPage,
    autoLoad: determineAutoLoad(
      cookies,
      props.location.pathname,
      disableClientFetch
    ),
    variables: [props.location.pathname],
  });

  useSubscription({
    type: ON_PAGE_FETCHED,
    action: onPageFetched,
  });

  useEffect(() => {
    if (!isAppMounted) {
      setIsAppMounted(true);

      // console.log('Mounting App.js', props.location.pathname)
      window._uxa = window._uxa || [];
      window._uxa.push([
        "setPath",
        window.location.pathname + window.location.hash.replace("#", "?__"),
      ]);
      console.log(
        "setPath",
        window.location.pathname + window.location.hash.replace("#", "?__")
      );

      if (
        props.location.pathname.indexOf("/privacy-policy") === 0 ||
        props.location.pathname.indexOf("/terms-of-use") === 0
      ) {
        setHideActiveModal(true);
      }

      const parsed = queryString.parse(props.location.search);
      if (parsed.disableClientFetch) {
        if (parsed.disableClientFetch === "true") {
          setDisableClientFetch(true);
          cookies.set("dcf", true, { path: "/" });
        } else if (parsed.disableClientFetch === "false") {
          setDisableClientFetch(false);
          cookies.set("dcf", false, { path: "/" });
        } else {
          console.warn(`You passed an invalid value for "disableClientFetch"`);
        }
      }
    }
  }, [isAppMounted]);

  // @todo: figure out how to get modalQueue to trigger this reliably
  useEffect(() => {
    if (hydrateData && !activeModal) {
      const modal = modalQueue.tryPop();
      if (modal) {
        setActiveModal(modal);
      }
    }
  }, [activeModal, modalQueue, hydrateData]);

  useEffect(() => {
    if (isHydrateMounted) {
      const cookies =
        typeof document === "object" ? new Cookies(document.cookie) : {};
      if (!userConsentsToTracking) {
        // if (checkUserTrackingConsent(cookies.cookies)) {
        setUserConsentsToTracking(true);
        // }
        // else {
        //   setUserConsentsToTracking(false)
        // }
      }
    }
  }, [isHydrateMounted, userConsentsToTracking, iubCookyExists]);

  useEffect(() => {
    if (props.location.pathname !== currentPath) {
      setCurrentPath(props.location.pathname);
      setHydrateData(props.pageResources.json.pageContext);
      setIsHydrateMounted(true);

      window._uxa.push([
        "trackPageview",
        window.location.pathname + window.location.hash.replace("#", "?__"),
      ]);
      console.log(
        "trackPageview",
        window.location.pathname + window.location.hash.replace("#", "?__")
      );

      if (currentPath) {
        // console.log(`pathname changed to ${props.location.pathname} from ${currentPath}`)
        // typeof window !== "undefined" && window.gtag && window.gtag("event", "click", {})
      } else {
        // console.log('initial pathname set', props.location.pathname)
      }

      // @todo: there must be a better way
      if (
        props.location.pathname.indexOf("/privacy-policy") === 0 ||
        props.location.pathname.indexOf("/terms-of-use") === 0
      ) {
        setHideActiveModal(true);
      } else {
        setHideActiveModal(false);
      }

      return () => {
        setHydrateData(null);
        setIsHydrateMounted(false);
      };
    }
  }, [currentPath, props.location.pathname]);

  useEffect(() => {
    if (isHydrateMounted) {
      if (hydrateData) {
        const cookies =
          typeof document === "object" ? new Cookies(document.cookie) : {};

        // @todo: probably rename this to `page` but can be talked out of that
        actions.hydrate.doSetLayoutData(
          transformLayoutData(hydrateData.content)
        );
        actions.hydrate.doSetSiteConfigData(
          transformSiteConfigData(hydrateData.site_config)
        );
        actions.hydrate.doSetBuildInfoData(
          transformBuildInfoData(hydrateData.build_info)
        );
        actions.hydrate.doSetDatabagData(
          transformDatabagData(hydrateData.databag)
        );

        if (hydrateData.__type === "allContentstackPartnerLocation") {
          actions.hydrate.doSetLocationData(
            transformLocationData(hydrateData.content)
          );
        } else if (hydrateData.__type === "allContentstackPressRelease") {
          actions.hydrate.doSetPostData(transformPostData(hydrateData.content));
        }

        const ageGateData =
          state.hydrate.siteConfigData?.blocks &&
          state.hydrate.siteConfigData?.blocks["interstitial--age-gate"]
            ? state.hydrate.siteConfigData?.blocks["interstitial--age-gate"]
                .component_data
            : null;

        if (ageGateData) {
          const ageGateModal = (
            <Modal
              modalTitle={ageGateData.title}
              modalBodyText={ageGateData.body}
              variant={ageGateData.variant}
              confirmButtonText="Enter"
              confirmButtonCallback={(v) => {
                const thisDate = new Date();
                thisDate.setDate(thisDate.getDate() + 1);
                cookies.set("upag", v, { path: "/", expires: thisDate });
              }}
              dismissModal={() => setActiveModal(modalQueue.tryPop())}
              extraClasses="modal-dialog-age-gate"
              shouldCloseOnOverlayClick={false}
              hide={hideActiveModal}
            />
          );

          let userHasPassedAgeGate =
            (cookies.cookies.upag && cookies.cookies.upag) >
              Date.now() - 60 * 60 * 24 * 1000 || false;
          if (!userHasPassedAgeGate) {
            if (hideActiveModal) {
              setActiveModal(null);
            } else {
              setUserShownAgeGate(true);
              const nextModalQueue = modalQueue;
              nextModalQueue.push(ageGateModal);
              setModalQueue(nextModalQueue);
              // @todo: useEffect not detecting change to ConsumerQueue object
              const modal = modalQueue.tryPop();
              setActiveModal(modal);
            }
          }
        }
      }
    }
  }, [
    isHydrateMounted,
    hydrateData,
    actions.hydrate,
    modalQueue,
    userShownAgeGate,
    hideActiveModal,
  ]);

  // useDataDogRum(isAppMounted);

  return (
    <>
      {/* <janeContext.Provider value={janeState}> */}
      <Layout>{props.children}</Layout>
      <AppScripts
        embedScripts={embedScripts}
        setEmbedScripts={setEmbedScripts}
        userConsentsToTracking={userConsentsToTracking}
      />
      {activeModal}
      {/* </janeContext.Provider> */}
    </>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

App.displayName = "App";

export default App;

// @todo: actually client load should stop breaking things and become a feature
const determineAutoLoad = (cookies, pathname, disableClientFetch) => {
  // @todo: this could be useful to have in the CMS
  const blockedPages = {
    "/about-us/careers/join-team": true,
    "/news": true,
    "/news/press-release": true,
    "/news/media-coverage": true,
    "/other-links/sitemap": true,
    "/batch": true,
  };

  const isDev = process.env.GHUB_CONTENTSTACK_ENVIRONMENT === "cannabistco_dev";

  if (!isDev) {
    // console.log('Client fetch disabled (rule 1)');
    return false;
  }

  if (blockedPages[pathname]) {
    // isDev && console.log('Client fetch disabled (rule 2)');
    return false;
  }

  if (disableClientFetch || cookies.cookies?.dcf == "true") {
    isDev && console.log("Client fetch disabled (rule 3)");
    return false;
  }

  const contentType = lookupContentType(pathname);
  if (contentType === "site_page" && pathname.indexOf("/news") === 0) {
    // isDev && console.log('Client fetch disabled (rule 4)');
    return false;
  }

  if (pathname.indexOf("/news/media-coverage/page-") === 0) {
    // isDev && console.log('Client fetch disabled (rule 5)');
    return false;
  }

  if (pathname.indexOf("/news/press-releases/page-") === 0) {
    // isDev && console.log('Client fetch disabled (rule 6)');
    return false;
  }

  // rec menus
  if (
    pathname.indexOf("/locations") === 0 &&
    pathname.indexOf("/recreational-menu") > -1
  ) {
    // isDev && console.log('Client fetch disabled (rule 7)');
    return false;
  }

  // med menus
  if (
    pathname.indexOf("/locations") === 0 &&
    pathname.indexOf("/medical-menu") > -1
  ) {
    // isDev && console.log('Client fetch disabled (rule 8)');
    return false;
  }

  return true;
};
